const state = {
    header_color: '#333',
    logo: false
}

const mutations = {
    SET_COLOR: (state, data) => {
        state.header_color = data;
    },
    SET_LOGO: (state, data) => {
        state.logo = data;
    },
}
const actions = {

}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}