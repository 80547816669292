<template>
  <div id="app">
    <my-header
      @childvalue="childvalue"
      v-show="!(name === 'meeting_data' || path === '/404')"
    ></my-header>
    <div class="page-mh">
      <keep-alive v-if="$route.meta.cache">
        <router-view :cur="curindex"></router-view>
      </keep-alive>
      <router-view :cur="curindex" v-else></router-view>
    </div>
    <my-footer
      v-show="!(name === 'meeting_data' || path === '/404')"
    ></my-footer>
    <fixed-msg
      v-show="!(name === 'meeting_data' || path === '/404')"
    ></fixed-msg>
  </div>
</template>

<script>
import myHeader from "@/components/header";
import myFooter from "@/components/footer";
import fixedMsg from "@/components/leavemsg";
export default {
  name: "App",
  data() {
    return {
      curindex: "",
      path: "",
      name:""
    };
  },
  components: {
    myHeader,
    myFooter,
    fixedMsg,
  },
  mounted() {
    this.childvalue();
    this.path = this.$route.path;
    this.name = this.$route.name
  },
  methods: {
    childvalue(val) {
      this.curindex = val;
    },
  },
  watch: {
    $route(to, from) {
      this.path = to.path;
      this.name = to.name
    },
  },
};
</script>
